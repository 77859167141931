import { useCallback } from 'react';
import { selector, useRecoilValue } from 'recoil';
import { apiClientState, authState } from '../appState';

export const customerPriceGroupState = selector<string | undefined>({
  key: 'customerPriceGroup',
  get: ({ get }) => {
    const auth = get(authState);

    return auth?.profile?.customer.priceGroup;
  },
});

export const customerContactGroupState = selector<string | undefined>({
  key: 'customerContactGroup',
  get: ({ get }) => {
    const auth = get(authState);

    return auth?.profile?.customer.contactGroup;
  },
});

export function useSetCustomerLocale(): (locale: string) => Promise<void> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (locale: string) => apiClient.post<void, { locale: string }>('/v1/users/set-locale', { locale }),
    [apiClient],
  );
}

const messages = {
  en: {
    complaintClaims: {
      listPage: {
        title: 'Complaints',
        table: {
          filter: {
            number: 'Number',
            invoiceNumber: 'Invoice number',
          },
          columns: {
            number: 'Number',
            invoiceNumber: 'Invoice number',
            state: 'Status',
            creationDate: 'Created',
            lastChangeDate: 'Last change',
          },
        },
      },
      states: {
        new: 'Created',
        processing: 'In process',
        accepted: 'Accepted',
        rejected: 'Declined',
      },
      complaintClaim: {
        newEntity: 'New complaint',
        invoiceNumber: {
          label: 'Invoice number',
          helperText: '',
          helperTextPreview: '',
        },
        lastChangeDate: {
          label: 'Last change',
          helperText: '',
          helperTextPreview: '',
        },
        creationDate: {
          label: 'Created',
          helperText: '',
          helperTextPreview: '',
        },
        state: {
          label: 'Status',
          helperText: '',
          helperTextPreview: '',
        },
        claimedQuantity: {
          label: 'Amount to complaint',
          helperText: '',
          helperTextPreview: '',
        },
        problem: {
          label: 'Issue',
          helperText: '',
          helperTextPreview: '',
        },
        reason: {
          label: 'Reason',
          helperText: '',
          helperTextPreview: '',
        },
        subject: {
          text: {
            label: 'Item to complaint',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            name: {
              label: 'Name of product',
              helperText: '',
              helperTextPreview: '',
            },
            sku: {
              label: 'SKU',
              helperText: '',
              helperTextPreview: '',
            },
          },
        },
      },
    },
  },
};

export default messages;

import { atom, selector } from 'recoil';
import { ComplaintProblemTypes } from '../../@sprinx/knihovka-types/complaintClaims';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export async function getComplaintProblemTypes(apiClient: ApiClient): Promise<ComplaintProblemTypes> {
  return apiClient.get<ComplaintProblemTypes, {}>('/v1/complaint-problem-types', {});
}

export const complaintProblemTypesInitialState = GlobalStateRegister.register(
  atom<ComplaintProblemTypes | undefined>({
    key: 'complaintProblemTypesInitial',
    default: undefined,
  }),
);

export const complaintProblemTypesQuery = selector<ComplaintProblemTypes>({
  key: 'complaintProblemTypes',
  get: ({ get }) => {
    const initial = get(complaintProblemTypesInitialState);
    if (initial) return initial;

    const apiClient = get(apiClientState);
    return getComplaintProblemTypes(apiClient);
  },
});

import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartTwoTone';
import AlarmIcon from '@material-ui/icons/AlarmTwoTone';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PetsIcon from '@material-ui/icons/PetsTwoTone';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MainNavigationContentItem from './MainNavigationContentItem';
import MainNavigationContentItemTree from './MainNavigationContentItemTree';

export type MainNavigationContentProps = StandardDivProps<MainNavigationContentClassKey>;

export type MainNavigationContentClassKey = 'root' | 'primary' | 'secondary';

const themeSettings = { name: 'MainNavigationContent' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
      primary: {
        flexGrow: 1,
        overflow: 'auto',
        paddingTop: theme.spacing(1),
      },
      secondary: {
        minHeight: theme.spacing(6),
      },
    }),
  themeSettings,
);

const MainNavigationContent = React.forwardRef<HTMLDivElement, MainNavigationContentProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslate();

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.primary}>
          {/* <MainNavigationContentItem icon={<HomeIcon />} primary={'Domov'} to='/' /> */}
          <MainNavigationContentItem
            icon={<PriorityHighIcon />}
            primary={t('app/specialMenuItems/campaign')}
            to='/special/campaign'
          />
          <MainNavigationContentItemTree primary={t('app/specialMenuItems/catalogue')} to='/catalogue' />
          <MainNavigationContentItem
            icon={<AttachMoneyIcon />}
            primary={t('app/specialMenuItems/zd_2023')}
            to='/special/zd'
          />
          <MainNavigationContentItem
            icon={<AlarmIcon />}
            primary={t('app/specialMenuItems/expiration')}
            to='/special/expiration'
          />
          <MainNavigationContentItem
            icon={<PetsIcon />}
            primary={t('app/specialMenuItems/current')}
            to='/special/zoopartner/current'
          />
          <MainNavigationContentItem
            icon={<NewReleasesIcon />}
            primary={t('app/specialMenuItems/new')}
            to='/special/zoopartner/new'
          />
          <MainNavigationContentItem
            icon={<AttachMoneyIcon />}
            primary={t('app/specialMenuItems/sale')}
            to='/special/sale'
          />

          <MainNavigationContentItem
            icon={<AnnouncementIcon />}
            primary={t('app/specialMenuItems/introduction')}
            to='/special/introduction'
          />
          <MainNavigationContentItem
            icon={<HospitalIcon />}
            primary={t('app/specialMenuItems/vetExclusive')}
            to='/special/vet-exclusive'
          />
          <MainNavigationContentItem
            icon={<AddShoppingCartIcon />}
            primary={t('app/specialMenuItems/massInsert')}
            to='/hromadny-nakup'
          />
          <MainNavigationContentItem
            icon={<ArrowDownwardIcon />}
            primary={t('app/specialMenuItems/downloadableDocuments')}
            to='/dokumenty-ke-stazeni'
          />
        </div>
        <div className={classes.secondary}>
          {/* <Divider /> */}
          <div style={{ paddingTop: 16, textAlign: 'center' }}>
            <Link component={RouterLink} to='/obchodni-podminky' color='textPrimary'>
              {t('app/specialMenuItems/termsAndConditions')}
            </Link>
          </div>
        </div>
      </div>
    );
  },
);

MainNavigationContent.displayName = 'MainNavigationContent';

export default withThemeProps(themeSettings)(MainNavigationContent);

const messages = {
  en: {
    invoices: {
      actions: {
        addNewInvoice: 'Add new invoice',
        all: 'All invoices',
        searchInvoice: 'Search in invoices',
      },
      listPage: {
        title: 'Invoices',
        table: {
          filter: {
            number: 'Number',
          },
          columns: {
            number: 'Number',
            invoiceDate: 'Created',
            dueDate: 'Payment date',
            total: 'Total',
            totalWithTax: 'Total incl VAT',
            billingAddress: 'Shipping address',
            orderNumber: 'Order number',
            unpaidAmount: 'Outstanding amount',
            download: 'Download',
          },
        },
      },
      invoice: {
        newEntity: 'New invoice',
        number: {
          label: 'Number',
          helperText: '',
          helperTextPreview: '',
        },
        invoiceDate: {
          label: 'Created',
          helperText: '',
          helperTextPreview: '',
        },
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        totalWithTax: {
          label: 'Total incl VAT',
          helperText: '',
          helperTextPreview: '',
        },
        billingAddress: {
          label: 'Shipping address',
          helperText: '',
          helperTextPreview: '',
        },
        orderNumber: {
          label: 'Order number',
          helperText: '',
          helperTextPreview: '',
        },
        deliveryNoteNumber: {
          label: 'Delivery note number',
          helperText: '',
          helperTextPreview: '',
        },
        paid: {
          label: 'Payed',
          helperText: '',
          helperTextPreview: '',
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Invoice lines',
          columns: {
            priceUnit: 'Price/pcs',
          },
        },
      },
    },
  },
};

export default messages;

const messages = {
  pl: {
    complaintClaims: {
      listPage: {
        title: 'Reklamacje',
        table: {
          filter: {
            number: 'Numer',
            invoiceNumber: 'Numer faktury',
          },
          columns: {
            number: 'Numer ',
            invoiceNumber: 'Numer faktury',
            state: 'Status',
            creationDate: 'Data utworzenia ',
            lastChangeDate: 'Data ostatniej zmiany',
          },
        },
      },
      states: {
        new: 'Utworzony',
        processing: 'W trakcie procesu',
        accepted: 'Przyjęty',
        rejected: 'Odrzucony',
      },
      complaintClaim: {
        newEntity: 'Nowa reklamacja',
        invoiceNumber: {
          label: 'Numer faktury',
          helperText: '',
          helperTextPreview: '',
        },
        lastChangeDate: {
          label: 'Data ostatniej zmiany',
          helperText: '',
          helperTextPreview: '',
        },
        creationDate: {
          label: 'Data utworzenia ',
          helperText: '',
          helperTextPreview: '',
        },
        state: {
          label: 'Status',
          helperText: '',
          helperTextPreview: '',
        },
        claimedQuantity: {
          label: 'Ilość reklamowana',
          helperText: '',
          helperTextPreview: '',
        },
        problem: {
          label: 'Problem',
          helperText: '',
          helperTextPreview: '',
        },
        reason: {
          label: 'Powód',
          helperText: '',
          helperTextPreview: '',
        },
        subject: {
          text: {
            label: 'Reklamowany przedmiot',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            name: {
              label: 'Nazwa produktu',
              helperText: '',
              helperTextPreview: '',
            },
            sku: {
              label: 'SKU',
              helperText: '',
              helperTextPreview: '',
            },
          },
        },
      },
    },
  },
};

export default messages;

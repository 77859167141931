import { atomFamily, selectorFamily } from 'recoil';
import { EntityId } from '../../@sprinx/knihovka-types/common';
import { ComplaintClaim, ComplaintClaimRecord } from '../../@sprinx/knihovka-types/complaintClaims';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type ComplaintClaimDetailComplaintClaim = ComplaintClaim<ComplaintClaimRecord, 'contact' | 'invoice'>;

export const getComplaintClaimDetail = (
  apiClient: ApiClient,
  params: { id: EntityId; populate?: string[] },
): Promise<ComplaintClaimDetailComplaintClaim> => {
  return apiClient.get<ComplaintClaimDetailComplaintClaim, { id: EntityId }>('/v1/complaint-claims/:id', params);
};

export const complaintClaimDetailInitialStateFamily = GlobalStateRegister.register(
  atomFamily<ComplaintClaimDetailComplaintClaim | undefined, EntityId>({
    key: 'complaintClaimDetailInitial',
    default: undefined,
  }),
  'complaintClaimDetailInitial',
);

export const complaintClaimDetailQuery = selectorFamily<ComplaintClaimDetailComplaintClaim, EntityId>({
  key: 'complaintClaimDetail',
  get: (complaintClaimId) => ({ get }) => {
    const initial = get(complaintClaimDetailInitialStateFamily(complaintClaimId));
    if (initial) return initial;

    const apiClient = get(apiClientState);

    return getComplaintClaimDetail(apiClient, { id: complaintClaimId, populate: ['contact'] });
  },
});

import React from 'react';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
import CldrLocaleCs from '@sprinx/react-globalize/CldrLocaleCs';
// import CldrLocaleDe from '@sprinx/react-globalize/CldrLocaleDe';
import CldrLocaleEn from '@sprinx/react-globalize/CldrLocaleEn';
import CldrLocalePl from '@sprinx/react-globalize/CldrLocalePl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { localeState, supportedLocaleCodesState } from './api/appState';
import MessagesCs from './MessagesCs';
// import MessagesDe from './MessagesDe';
import MessagesEn from './MessagesEn';
import MessagesPl from './MessagesPl';

// CLDRS
// const CldrLocaleCs = loadable(() => import('@sprinx/react-globalize/CldrLocaleCs'), { fallback: <ComponentLoader /> });

// Messages
// const MessagesCs = loadable(() => import('./MessagesCs'), { fallback: <ComponentLoader /> });

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  const defaultLocale = useRecoilValue(localeState);
  const setLocale = useSetRecoilState(localeState);
  const supportedLocales = useRecoilValue(supportedLocaleCodesState);

  return (
    <GlobalizeProvider
      cldrs={{ cs: CldrLocaleCs, en: CldrLocaleEn, pl: CldrLocalePl }}
      messages={{ cs: MessagesCs, en: MessagesEn, pl: MessagesPl }}
      defaultLocale={defaultLocale || 'cs'}
      supportedLocales={supportedLocales || ['cs']}
      onPreferredLocaleChanged={setLocale}
      // dontResolveLocale
      // defferInitialization={onAuth}
      // cache={cache}
      // loadSetting={loadLanguageSetting}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;

const plMessages = {
  pl: {
    homePage: {
      title: 'Strona główna',
      gotoShopping: 'Kupić',
      downloadPriceLists: {
        title: 'Cenniki do pobrania',
        waitingMessage: 'Cenniki do pobrania będą dostępne po ich wygenerowaniu',
        downloadProblem: 'Generowanie cenników nie powiodło się',
        ready: 'Cenniki są gotowe do pobrania ',
        xml: 'Katalog produktów dla E-shop - XML',
        xmlC: 'Rozszerzony katalog produktów dla E-shop - XML',
        stock: 'Dostępność produktów ',
        cusPriceList: 'Cennik klienta',
        cusPriceListEan: 'Cennik klienta z EAN ',
      },
      signout: {
        label: 'Wyloguj',
        popupLabel: 'Wyloguj',
        question: 'Proszę potwierdzić wylogowanie',
        successful: 'Wylogowałeś się poprawnie ',
        unsuccessful: 'Wystąpił błąd w czasie wylogowania.',
      },
      login: {
        label: 'Zaloguj ',
        andHome: 'Zaloguj do strony głównej ',
        changeLocale: 'Zmienić język',
        username: 'Nazwa użytkownika',
        password: 'Hasło',
        passwordCheck: 'Potwierdź hasło ',
        passwordChanged: 'Hasło zostało zmienione',
        confirmPassword: 'Potwierdź hasło ',
        forgottenPassword: 'Zresetuj hasło ',
        sendButton: 'Wyślij ',
        contactEmail: 'Email kontaktowy ',
        fullName: 'Imię i nazwisko',
        createUser: 'Utwórz użytkownika oddziału ',
        error1: 'Błąd logowania ',
        error2: 'Błędna nazwa lub hasło',
        error3: 'Wystąił błąd w czasie wysyłki',
        question: 'Pytanie o produkt',
      },
      passwordReset: {
        passwordSent: 'Hasło zostało wysłane na Twój adres e-mail',
        error: 'Nie udało się zresetować hasła',
      },
    },

    cataloguePage: {},

    customerProfileComplaintClaimsPage: {
      title: 'Reklamacje',
      filter: {
        number: 'Numer reklamacji',
      },
    },

    customerProfileComplaintClaimDetailPage: {},

    customerProfileCreditNotesPage: {
      title: 'Nota kredytowa',
      filter: {
        number: 'Numer noty kredytowej',
      },
    },

    customerProfileCreditNoteDetailPage: {
      subjectList: {
        title: 'Produkty',
      },
    },

    customerProfileInvoicesPage: {
      title: 'Faktury',
      filter: {
        number: 'Numer faktury',
      },
    },

    customerProfileInvoiceDetailPage: {
      subjectList: {
        title: 'Pozycje faktury',
      },
    },

    customerProfileDocumentsPage: {
      title: 'Dokumenty',
    },

    customerProfilePriceListsPage: {
      title: 'Cenniki',
    },

    customerProfileShoppingListsPage: {
      title: 'Lista zakupów',
    },

    customerProfileWatchDogsPage: {
      title: 'Psy stróżujące',
    },

    customerProfileOrderDetailPage: {
      actions: {
        import: 'Do koszyka',
      },
      phase: {
        label: 'Status',
      },
      subjectList: {
        title: 'Zamówione produkty',
      },
      totalWithTax: {
        label: 'Razem z VAT ',
      },
    },

    customerProfileOrdersPage: {
      title: 'Zamówienia',
      addUndeliveredItemsToCart: 'Dodaj niedostarczone produkty do koszyka',
    },

    customerProfilePage: {
      title: 'Profil użytkownika ',
      changeTitle: 'Zmiana danych',
      setupDocumentsSending: 'Konfiguracja wysyłania dokumentów',
      changedSuccessfully: 'Twoje dane zostały zmienione',
      error: 'Błąd',
      name: 'Nazwa',
      email: 'Email',
      changePassword: {
        title: 'Zmiana hasła',
        confirmPassword: 'Wprowadzone hasła nie pasują do siebie',
        passwordStrongEnough: [
          'Słabe hasło',
          'Hasło musi zawierać co najmniej 8 znaków',
          'Hasło nie może zawierać spacji',
        ],
      },
      customer: 'Klient',
      company: 'Firma',
      ico: 'Numer identyfikacyjny',
      dic: 'Numer NIP',
      branchUsers: 'Użytkownicy oddziału',
      changeData: 'Zmiana danych',
    },

    orders: {
      number: 'Numer',
      orderNumber: 'Numer zamówienia',
      exOrderNumber: 'Zewnętrzy numer zamówienia ',
      finalWithoutTax: 'Razem bez VAT',
      final: 'Razem',
      shippingAddress: 'Adres dostawy',
      dateOfOrder: 'Data zamówienia ',
      state: 'Status',
      suppliedQuantity: 'Dostarczona ilość ',
      owner: 'Właściciel',
      search: 'Znajdź',
      searchHide: 'Ukryj filtr',
    },

    documents: {
      documentNumber: 'Numer dokumentu',
      date: 'Data',
      orderNumber: 'Numer zamówienia ',
      externalOrderNumber: 'Zewnętrzy numer zamówienia ',
      type: 'Typ',
      DNote: 'Dowód dostawy',
      invoice: 'Faktura',
      createClaim: 'Utwórz reklamację ',
    },

    priceLists: {
      label: 'Cenniki',
      dateOfLastUpdate: 'Data ostatniej aktualizacji cennika na stronie internetowej',
      preGeneration: 'Generacja słowników',
      autoGenerateFeeds: 'Automatyczne generowanie feedów XML',
      autoGenerateFeedsHelper: 'Po aktywacji',
      recountButton: 'Przeliczenie cennika',
    },

    shoppingList: {
      rename: 'Przemianowanie',
      quantityAbbreviation: 'szt',
    },

    app: {
      products: {
        stockQuantityInfo: {
          onStock: 'w magazynie ',
          notOnStock: 'Nie dostępne',
          notOnStockKnownWhenExpected: 'Przewidywany termin dostawy {timing}',
          pcs: '{qty, plural, one {{qtyF} szt w magazynie} few {{qtyF} szt w magazynie} other {{qtyF} szt w magazynie}}',
        },
      },

      pickDeliveryPlace: {
        label: 'Adres dostawy',
        helperText: 'Wybierz placówkę do której ma być dostarczone zamówienie',
        requiredError: 'undefined',
      },

      orderSummary: {
        label: 'undefined',
      },

      shoppingCart: {
        emptyShoppingCart: 'Twóoj koszyk jest pusty',
        orderNumber: 'Numer zamówienia',
        noteForSupplier: 'Uwagi dla dostawców',
        requiredDateOfDelivery: 'Oczekiwana data dostawy',
        requiredDateOfDeliveryHelper: 'Proszę wypełnić zgodnie z warunkami dostawy',
        priceWithTax: 'Cena zawiera VAT',
        priceWithoutTax: 'Razem bez VAT',
        createOrder: 'Utwórz zamówienie',
        resetCart: 'Resetuj koszyk',
        acceptReset: 'Potwierdź reset ',
        orderButton: 'Zamówienie',
        notEnoughCZK: 'Twoje zamówienie ma wartość niższą niż 3500 Kč. Możesz zostać obciążony kosztami transportu.',
        notEnoughEUR: 'Twoje zamówienie ma wartość niższą niż250 EUR. Możesz zostać obciążony kosztami transportu.',
        popup: {
          label: 'Masz już pozycję w koszyku',
          info: 'Masz koszyk wypełniony zamówieniem w toku.',
          confirm: 'OK',
          emptyShoppingCart: 'Utworzono pusty koszyk',
          continueWithCart: 'Kontynuujesz z koszykiem w toku',
        },
      },

      continueShopping: {
        buttonLabel: 'Kontynuuj zakupy',
        thanks: 'Dzięujemy za Twoje zamówienie',
        infoBelow: 'Poniżej znajdziesz informację o swoim zamówieniu.',
        orderNo: 'Niumer zamówienia ',
      },

      specialMenuItems: {
        campaign: 'Kampanie',
        introduction: 'Nowości ',
        current: 'ZooPartner – aktualny',
        new: 'Zoopartner - przygotowujemy',
        sale: 'Wyprzedaż ',
        vetExclusive: 'Wyłącznie weterynaria',
        zd_2023: 'Zákaznické dny',
        expiration: 'Krótki termin ważności',
        catalogue: 'Wszystkie produkty',
        massInsert: 'Dodaj zbiorczo do koszyka',
        downloadableDocuments: 'Dokumenty do pobrania',
        termsAndConditions: 'Ogólne warunki handlowe+',
      },

      campaign: {
        palettes: 'Palety ',
        cardboard: 'Kartony',
        display: 'Display',
        searchDepartment: 'Szukaj w dziale',
        name: 'Nazwa',
        code: 'Kod ',
        cheapest: 'Od najtańszych',
        mostExpensive: 'Od najdroższych ',
        changePage: 'Zmień stronę ',
        goTo: 'Pomiń',
        searchCatalogue: 'Szukaj w całym katalogu',
        searchFoundNothing: 'Proszę wybrać przedmiot ',
      },

      productInfo: {
        package: 'Opakowanie',
        withTax: 'Z VAT ',
        withoutTax: 'Bez VAT',
        priceWithTax: 'Cenz zawiera VAT',
        priceWithoutTax: 'Cena bez VAT',
        basePriceWithTax: 'Cena podstawowa z VAT',
        basePriceWithoutTax: 'Cena podstawowa bez VAT',
        priceQuantity: 'Cena/szt',
        expiration: 'Data ważności',
        minimalOrder: 'Minimalna wielkość zamówienia ',
        buyButton: 'Kup',
        tax: 'VAT',
        quantity: 'Ilość',
        suppliedQuantity: 'Dostarczona ilość ',
        dmicWithTax: 'RRSO z VAT',
        dmocWithTax: 'Rekomendowana cena detaliczna z VAT',
        withTaxForPiece: 'z VAT/szt',
        withoutTaxForPiece: 'bez VAT/szt',
        questionButton: 'Mam pytanie',
        description: 'Szczegóły',
        parameters: 'Parametry',
        ean: 'EAN',
        hide: 'Ukryj ',
        show: 'Wyświetl ',
        change: {
          title: 'Zamień produkt',
          text: 'szt oryginalnego produktu zostaną wymienione',
          pack: 'Opakowanie',
          packs: 'Opakwania ',
          noOfPacks: 'szt w opakowaniu ',
          wishToChange: 'Czy chcesz wymienić oryginalny produkt na nowo wybrany produkt?',
        },
        cheaper: {
          title: 'Tańszy wariant produktu',
          text: 'Możesz kupić inne warianty produktu w szczegółach produktu',
          one: 'W koszyku jest jeden produkt który można kupić taniej',
          moreThanFour1: 'W koszyku jest ',
          moreThanFour2: 'Produkty które można kupić taniej',
          notZero1: 'Są w koszyku',
          notZero2: 'produkty które mogą być kupione taniej',
          palett: 'Palety muszą być zapisane  w osobnym zamówieniu',
        },
      },

      vlp: {
        title: 'Ten przedmiot podlega ograniczeniom sprzedaży ',
        label: 'VLP',
      },

      massInsert: {
        textLines: 'Line tekstu',
        textLinesHelper:
          'Wprowadź wartości `SKU;ILOŚĆ` lub `SKU QTY` np. `453-1063;2`. Jeśli wprowadzisz więcej niż 1 produkt',
        checkFormat: 'Kontola formatu',
        afterCheckFormat: 'Po kontroli formatu',
        putInCart: 'Dodaj do koszyka',
        success: 'Wszystkie zidentyfikowane produkty zostały dodane do koszyka',
        error: {
          noData: 'Nie są wprowadzane żadne dane wejściowe',
          nothingToImport: 'Nic do zaimportowania',
          wrongFormat: 'Niepoprawny format',
          wrongID: 'Niepoprawny kod produktu',
          wrongQuantity: 'Niepoprawna ilość produktów',
          notFindItem: 'Nieznany produkt',
        },
      },

      backButton: {
        label: 'Z powrotem',
      },

      homeButton: {
        label: 'Dom',
      },
    },
  },
};

export default plMessages;

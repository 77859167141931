const csMessages = {
  cs: {
    homePage: {
      title: 'Úvodní stránka',
      gotoShopping: 'Nakupovat',
      downloadPriceLists: {
        title: 'Ceníky ke stažení',
        waitingMessage: 'Ceníky ke stažení budou k dipozici po jejich vygenerovaní.',
        downloadProblem: 'Nezdařilo se získlat informace k stažení ceníků.',
        ready: 'Ceníky ke stažení jsou připraveny',
        xml: 'Katalog produktů pro eshop - XML (xml)',
        xmlC: 'Katalog produktů pro eshop rozšířený- XML (xml)',
        stock: 'Skladová dostupnost - XML (xml)',
        cusPriceList: 'Zákaznický ceník',
        cusPriceListEan: 'Zákaznický ceník s EAN',
      },
      signout: {
        label: 'Odhlásit',
        popupLabel: 'Odhlášení',
        question: 'Opravdu se chcete odhlásit?',
        successful: 'Byl jste úspěšně odhlášen...',
        unsuccessful: 'Při odhlašování došlo k chybě!',
      },
      login: {
        label: 'Přihlásit',
        andHome: 'Přihlásit a domů',
        changeLocale: 'Změnit jazyk',
        username: 'Uživatelské jméno',
        password: 'Heslo',
        passwordCheck: 'Heslo pro ověření',
        passwordChanged: 'Heslo bylo změněno.',
        confirmPassword: 'Potvrzení hesla',
        forgottenPassword: 'Zapomenuté heslo',
        sendButton: 'Odeslat',
        contactEmail: 'Kontaktní email',
        fullName: 'Jméno a příjmení',
        createUser: 'Vytvořit uživatele pobočky',
        error1: 'Chyba přihlášení.',
        error2: 'Nesprávné jméno nebo heslo.',
        error3: 'Při odesílání nastala chyba.',
        question: 'Dotaz k produktu',
      },
      passwordReset: {
        passwordSent: 'Heslo bylo odesláno na Váš e-mail',
        error: 'Nepodařilo se obnovit heslo.',
      },
    },

    navigation: {
      campaign: 'Kampaně',
      catalogue: 'Všechny produkty',
      zd: 'Zákaznické dny',
      expiration: 'Krátká expirace',
      current: 'ZooPartner – aktuální',
      new: 'Zoopartner - připravujeme',
      sale: 'Výprodej',
      introduction: 'Novinky',
      vetExclusive: 'Vet exclusive',
      hromadnyNakup: 'Hromadné vložení do košíku',
      dokumentyKeStazeni: 'Dokumenty ke stažení',
      obchodniPodminky: 'Všeobecné obchodní podmínky',
    },

    cataloguePage: {},

    customerProfileComplaintClaimsPage: {
      title: 'Reklamace',
      filter: {
        number: 'Číslo reklamaca',
      },
    },

    customerProfileComplaintClaimDetailPage: {},

    customerProfileCreditNotesPage: {
      title: 'Dobropisy',
      filter: {
        number: 'Číslo dobropisu',
      },
    },

    customerProfileCreditNoteDetailPage: {
      subjectList: {
        title: 'Položky',
      },
    },

    customerProfileInvoicesPage: {
      title: 'Faktury',
      filter: {
        number: 'Číslo faktury',
      },
    },

    customerProfileInvoiceDetailPage: {
      subjectList: {
        title: 'Položky faktury',
      },
    },

    customerProfileDocumentsPage: {
      title: 'Dokumenty',
    },

    customerProfilePriceListsPage: {
      title: 'Ceníky',
    },

    customerProfileShoppingListsPage: {
      title: 'Nákupní seznamy',
    },

    customerProfileWatchDogsPage: {
      title: 'Hlídací psi',
    },

    customerProfileOrderDetailPage: {
      actions: {
        import: 'Vložit do košíku',
      },
      phase: {
        label: 'Stav',
      },
      subjectList: {
        title: 'Položky objednávky',
      },
      totalWithTax: {
        label: 'Celkem s DPH',
      },
    },

    customerProfileOrdersPage: {
      title: 'Objednávky',
      addUndeliveredItemsToCart: 'Přidat nedodané položky do košíku',
    },

    customerProfilePage: {
      title: 'Uživatelský profil',
      changeTitle: 'Změna údajů',
      setupDocumentsSending: 'Nastavit odesílání dokumentů',
      changedSuccessfully: 'Vaše údaje byli úspěšně změněny',
      error: 'Došlo k chybě',
      name: 'Jméno',
      email: 'E-mail',
      changePassword: {
        title: 'Změnit heslo',
        confirmPassword: 'Zadaná hesla se neshodují',
        passwordStrongEnough: [
          'Heslo není dostatečně silné. ',
          'Heslo musí obsahovat alespoň 8 znaků, z nichž je alespoň jeden velké písmeno, malé písmeno a číslo.',
          'Heslo nesmí obsahovat mezery.',
        ],
      },
      customer: 'Zakaznik',
      company: 'Firma',
      ico: 'IČO',
      dic: 'DIČ',
      branchUsers: 'Uživatelé poboček',
      changeData: 'Změnit údaje',
    },

    orders: {
      number: 'Číslo',
      orderNumber: 'Číslo objednávky',
      exOrderNumber: 'Číslo objednávky externí',
      finalWithoutTax: 'Celkem bez DPH',
      final: 'Celkem',
      shippingAddress: 'Adresa dodání',
      dateOfOrder: 'Datum objednání',
      state: 'Stav',
      suppliedQuantity: 'Dodané množství',
      owner: 'Vlastník',
      search: 'Vyhledat',
      searchHide: 'Skrýt filtr',
    },

    documents: {
      documentNumber: 'Číslo dokumentu',
      date: 'datum',
      orderNumber: 'Číslo objednávky',
      externalOrderNumber: 'Číslo objednávky externí',
      type: 'typ',
      DNote: 'dodací list',
      invoice: 'faktura',
      createClaim: 'Vytvořit reklamaci',
    },

    priceLists: {
      label: 'Ceníky',
      dateOfLastUpdate: 'Datum poslední aktualizace ceníku na webu',
      preGeneration: 'Předgenerování slovníků',
      autoGenerateFeeds: 'Automaticky generovat XML feedy',
      autoGenerateFeedsHelper:
        'Po aktivaci se budou pro Vás předgenerovávat feedy ceníku, které můžete získat pomocí URL.',
      recountButton: 'Přepočítat ceník',
    },

    shoppingList: {
      rename: 'Přejmenovat',
      quantityAbbreviation: 'ks',
    },

    app: {
      products: {
        stockQuantityInfo: {
          onStock: 'skladem',
          notOnStock: 'není skladem',
          notOnStockKnownWhenExpected: 'XXX {timing}', //'očekávané datum naskladnění {timing}',
          pcs: '{qty, plural, one {{qtyF} kus skladem} few {{qtyF} kusy skladem} other {{qtyF} kusů skladem}}',
        },
      },

      pickDeliveryPlace: {
        label: 'Místo doručení',
        helperText: 'Vyberte provozovnu do které bude objednávka doručena',
        requiredError: 'Vyberte prosím provozovnu',
      },

      orderSummary: {
        label: 'XXX',
      },

      shoppingCart: {
        emptyShoppingCart: 'Váš košík je prázdný',
        orderNumber: 'Vaše číslo objednávky',
        noteForSupplier: 'Poznámka pro dodavatele',
        requiredDateOfDelivery: 'Požadované datum dodávky',
        requiredDateOfDeliveryHelper: 'Vyplňte dle aktuálních termínů závozu',
        priceWithTax: 'Cena s DPH',
        priceWithoutTax: 'Celkem bez DPH',
        createOrder: 'Vytvořit objednávku',
        resetCart: 'Resetovat košík',
        acceptReset: 'Potvrdit reset',
        orderButton: 'Objednat',
        notEnoughCZK: 'Neobjednali jste v limitu 3500 Kč, může vám být účtována doprava.',
        notEnoughEUR: 'Neobjednali jste v limitu 250€, může vám být účtována doprava.',
        popup: {
          label: 'Máte již rozpracovaný košík',
          info: 'Máte naplněný košík rozpracovanou objednávkou.',
          confirm: 'OK',
          emptyShoppingCart: 'Byl vytvřen prázdný košík',
          continueWithCart: 'Pokračujete v rozpracovaném košíku',
        },
      },

      continueShopping: {
        buttonLabel: 'Pokračovat v nákupu',
        thanks: 'Děkujeme za vaši objednávku',
        infoBelow: 'Niže najdete informace ke své objednávce.',
        orderNo: 'Číslo objednávky: ',
      },

      specialMenuItems: {
        campaign: 'Kampaně',
        introduction: 'Novinky',
        current: 'ZooPartner – aktuální',
        new: 'Zoopartner - připravujeme',
        sale: 'Výprodej',
        vetExclusive: 'Vet exclusive',
        zd_2023: 'Zákaznické dny',
        expiration: 'Krátká expirace',
        catalogue: 'Všechny produkty',
        massInsert: 'Hromadné vložení do košíku',
        downloadableDocuments: 'Dokumenty ke stažení',
        termsAndConditions: 'Všeobecné obchodní podmínky',
      },

      campaign: {
        palettes: 'Palety',
        cardboard: 'Karton',
        display: 'Display',
        searchDepartment: 'Vyhledat v oddělení',
        name: 'Název',
        code: 'Kód',
        cheapest: 'Od nejlevnějšího',
        mostExpensive: 'Od nejdražšího',
        changePage: 'Změnit stránku',
        goTo: 'Přejít',
        searchCatalogue: 'Hledat v celém katalogu',
        searchFoundNothing: 'Váš výběr neobsahuje žádné položky.',
      },

      productInfo: {
        package: 'Balení',
        withTax: 's DPH',
        withoutTax: 'bez DPH',
        priceWithTax: 'Cena s DPH',
        priceWithoutTax: 'Cena bez DPH',
        basePriceWithTax: 'Základní cena s DPH',
        basePriceWithoutTax: 'Základní cena bez DPH',
        priceQuantity: 'Cena/ks',
        expiration: 'Expirace',
        minimalOrder: 'Min.Obj.Mn.',
        buyButton: 'Koupit',
        tax: 'DPH',
        quantity: 'Množství',
        suppliedQuantity: 'Dodané množství',
        dmicWithTax: 'DMIC s DPH',
        dmocWithTax: 'DMOC s DPH',
        withTaxForPiece: 's DPH za ks',
        withoutTaxForPiece: 'bez DPH za ks',
        questionButton: 'Mám dotaz',
        description: 'Podrobnosti',
        parameters: 'Parametry',
        ean: 'EAN',
        hide: 'Skrýt',
        show: 'Zobrazit',
        change: {
          title: 'Nahrazení produktu',
          text: 'kusů původního zboží bude nahrazeno',
          pack: 'balením',
          packs: 'baleními',
          noOfPacks: 'kusů v jednom balení',
          wishToChange: 'Přejete si nahradit původní produkt nově vybraným produktem?',
        },
        cheaper: {
          title: 'levnější varianty produktu',
          text: 'V detailu produktu můžete nakoupit další varianty produktu',
          one: 'V košíku je 1 produkt, který se dá koupit levněji',
          moreThanFour1: 'V košíku je',
          moreThanFour2: 'produktů, které se dají koupit levněji',
          notZero1: 'V košíku jsou',
          notZero2: 'produkty, které se dají koupit levněji',
          palett: 'Množstevní jednotku paleta je potřeba zadat na samostatné objednávce',
        },
      },

      vlp: {
        title: 'Na tuto položku se vztahují restrikce na omezení prodeje',
        label: 'VLP',
      },

      massInsert: {
        textLines: 'Textové řádky',
        textLinesHelper:
          'Vložte hodnoty `SKU;MNOZSTVI` nebo `SKU MNOZSTVI`, např. `453-1063;2`. Při více než 1 produktu, musí být další SKU na novém řádku. Můžete také využít možnost importu z Excelu, pomocí kopírování a následného vložení sloupců.',
        checkFormat: 'Kontrola formátu',
        afterCheckFormat: 'Po kontrole formátu',
        putInCart: 'Vložení do košíku',
        success: 'Všechny identifikované produkty byl přidány do košíku.',
        error: {
          noData: 'Nejsou zadána vstupní data.',
          nothingToImport: 'Není co importovat',
          wrongFormat: 'Neplatný formát',
          wrongID: 'Neplatný identifikátor produktu',
          wrongQuantity: 'Neplatné množství produktů',
          notFindItem: 'Produkt nenalezen',
        },
      },

      backButton: {
        label: 'Zpět',
      },

      homeButton: {
        label: 'Domov',
      },
    },
  },
};

export default csMessages;

import { QueryOrderSettings, QueryPaginationSettings, TableFilterField } from '@sprinx/query-builder';
import { useCallback } from 'react';
import { atom, DefaultValue, selector, useRecoilValue } from 'recoil';
import {
  ComplaintClaim,
  ComplaintClaimRecord,
  ComplaintClaimSubject,
  MoleculerListResult,
} from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import {
  buildListQuery,
  createListInitialSettings,
  ListCallParams,
} from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';
import transformComplaintClaim from './transformComplaintClaim';

export interface SaveComplaintClaimFormValues {
  claimedQuantity: number | undefined;
  invoice: string | undefined;
  problem: string | undefined;
  reason: string | undefined;
  subject: ComplaintClaimSubject;
  subjectInvoiceRowNum: number | undefined;
}

export interface SaveComplaintClaimFormErrors {
  claimedQuantity?: string;
  invoice?: string;
  problem?: string;
  reason?: string;
  subject?: string;
  subjectInvoiceRowNum?: string;
}

export interface SaveComplaintClaimFormTouched {
  claimedQuantity?: boolean;
  invoice?: boolean;
  problem?: boolean;
  reason?: boolean;
  subject?: boolean;
  subjectInvoiceRowNum?: boolean;
}

export function useCreateComplaintClaim(): (params: SaveComplaintClaimFormValues) => Promise<void> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: SaveComplaintClaimFormValues) =>
      apiClient.post<void, SaveComplaintClaimFormValues>('/v1/complaint-claims', params),
    [apiClient],
  );
}

export type ComplaintClaimListItem = ComplaintClaim<ComplaintClaimRecord, 'contact' | 'invoice'> & {
  href: string;
};

export const complaintClaimsQuery = selector<MoleculerListResult<ComplaintClaimListItem>>({
  key: 'complaintClaims',
  get: ({ get }) => {
    const initialState = get(complaintClaimsInitialState);
    if (initialState) {
      return initialState;
    }

    const apiClient = get(apiClientState);
    const locale = get(localeState);
    const params = get(complaintClaimsCallParamsState);
    get(complaintClaimsReloadTrigger);

    return getComplaintClaims(apiClient, locale, params);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(complaintClaimsReloadTrigger, (n) => n + 1);
    }
  },
});

export const complaintClaimsCallParamsState = GlobalStateRegister.register(
  atom<ListCallParams>({
    key: 'complaintClaimsCallParams',
    default: createListInitialSettings({
      rowsPerPage: 10,
      orderBy: 'number',
      orderDirection: 'desc',
    }),
  }),
);

export const complaintClaimsOrderByState = selector<QueryOrderSettings>({
  key: 'complaintClaimsOrderBy',
  get: ({ get }) => {
    const params = get(complaintClaimsCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(complaintClaimsCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const complaintClaimsPaginationState = selector<QueryPaginationSettings & { total: number }>({
  key: 'complaintClaimsPagination',
  get: ({ get }): QueryPaginationSettings & { total: number } => {
    const params = get(complaintClaimsCallParamsState);
    const queryResult = get(complaintClaimsQuery);

    return {
      page: queryResult.page || params.pagination.rowsPerPage,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(complaintClaimsCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          // ...prevValue.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const complaintClaimsFilterState = selector<TableFilterField<any>[]>({
  key: 'complaintClaimsFilter',
  get: ({ get }) => {
    const params = get(complaintClaimsCallParamsState);
    return (params.filter as TableFilterField<any>[]) || [];
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const nextFilter = newValue.filter(Boolean);

      set(complaintClaimsCallParamsState, (prev) => ({
        ...prev,
        filter: nextFilter,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const complaintClaimsInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<ComplaintClaimListItem> | undefined>({
    key: 'complaintClaimsInitialState',
    default: undefined, // createListDefaultResult<ComplaintClaimsProduct>(),
  }),
);

export function getComplaintClaims(
  apiClient: ApiClient,
  locale: string,
  params: ListCallParams,
): Promise<MoleculerListResult<ComplaintClaimListItem>> {
  return apiClient
    .get<MoleculerListResult<Omit<ComplaintClaimListItem, 'href'>>, Record<string, any>>(
      '/v1/complaint-claims',
      buildListQuery(params),
    )
    .then((r) => ({
      ...r,
      rows: transformComplaintClaim(locale, r.rows),
    }));
}

export const complaintClaimsReloadTrigger = atom<number>({
  key: 'complaintClaimsReloadTrigger',
  default: 0,
});

const messages = {
  en: {
    homePage: {
      title: 'Home page',
      gotoShopping: 'Buy',
      downloadPriceLists: {
        title: 'Download pricelist',
        waitingMessage: 'Pricelists generating in progress',
        downloadProblem: 'Pricelists generating failed',
        ready: 'Pricelists for download are ready',
        xml: 'Product Catalogue for Eshop - XML',
        xmlC: 'Advanced Product Catalogue for Eshop - XML',
        stock: 'Stock availability',
        cusPriceList: 'Customer pricelist',
        cusPriceListEan: 'Customer pricelist with EANs',
      },
      signout: {
        label: 'Logoff',
        popupLabel: 'Logoff',
        question: 'Please confirm logoff.',
        successful: 'Logoff succesfull',
        unsuccessful: 'Logoff failed',
      },
      login: {
        label: 'Logon',
        andHome: 'Logon and home',
        changeLocale: 'Change language',
        username: 'User name',
        password: 'Password',
        passwordCheck: 'Confirm password',
        passwordChanged: 'Pasword has been changed.',
        confirmPassword: 'Confirm password',
        forgottenPassword: 'Reset password',
        sendButton: 'Send',
        contactEmail: 'Contact email',
        fullName: 'Name and surname',
        createUser: 'Create branch user',
        error1: 'Logon failed',
        error2: 'Incorrect name or password',
        error3: 'Send failed',
        question: 'Question to product',
      },
      passwordReset: {
        passwordSent: 'Password has been sent to your email',
        error: 'Password reset failed',
      },
    },
    catalogueOage: {},
    customerProfileComplaintClaimsPage: {
      title: 'Complaints',
      filter: {
        number: 'Number of Complaints',
      },
    },
    customerProfileComplaintClamDetailPage: {},
    customerProfileCreditNotesPage: {
      title: 'Credit notes',
      filter: {
        number: 'Number of Credit Note',
      },
    },
    customerProfileCreditNoteDetailPage: {
      subjectList: {
        title: 'Item',
      },
    },
    customerProfileInvoicesPage: {
      title: 'Invoices',
      filter: {
        number: 'Number of invoice',
      },
    },
    customerProfileInvoiceDetailPage: {
      subjectList: {
        title: 'Invoice items',
      },
    },
    customerProfileDocumentsPage: {
      title: 'Documents',
    },
    customerProfilePriceListsPage: {
      title: 'Pricelists',
    },
    customerProfileShoppingListsPage: {
      title: 'Purchase lists',
    },
    customerProfileWatchDogsPage: {
      title: 'Watch dogs',
    },
    customerProfileOrderDetailPage: {
      actions: {
        import: 'To the basket',
      },
      phase: {
        label: 'Status',
      },
      subjectList: {
        title: 'Order items',
      },
      totalWithTax: {
        label: 'Including VAT',
      },
    },
    customerProfileOrdersPage: {
      title: 'Orders',
      addUndeliveredItemsToCart: 'Add undelivered items to the basket',
    },
    customerProfilePage: {
      title: 'User profile',
      changeTitle: 'Change data',
      setupDocumentsSending: 'Configuration of documents sending',
      changedSuccessfully: 'Your data has been changed',
      error: 'Error',
      name: 'Name',
      email: 'Email',
      changePassword: {
        title: 'Change password',
        confirmPassword: 'Passwords do not match',
        passwordStrongEnough: [
          'Weak password',
          'Password must contains at least 8 characters',
          'Pasword must not contains spaces',
        ],
      },
      customer: 'Customer',
      company: 'Company',
      ico: 'ID',
      dic: 'Tax ID',
      branchUsers: 'Branch users',
      changeData: 'Change data',
    },
    orders: {
      number: 'Number',
      orderNumber: 'Order number',
      exOrderNumber: 'External order number',
      finalWithoutTax: 'Excluding VAT',
      final: 'Total',
      shippingAddress: 'Shipping address',
      dateOfOrder: 'Order date',
      state: 'Status',
      suppliedQuantity: 'Delivered amount',
      owner: 'Owner',
      search: 'Find',
      searchHide: 'Hide filter',
    },
    documents: {
      documentNumber: 'Document number',
      date: 'Date',
      orderNumber: 'Order number',
      externalOrderNumber: 'External order number',
      type: 'Type',
      DNote: 'Delivery note',
      invoice: 'Invoice',
      createClaim: 'New Complaint',
    },
    priceLists: {
      label: 'Pricelists',
      dateOfLastUpdate: 'Pricelist list update date',
      preGeneration: 'Generating of vocabulary',
      autoGenerateFeeds: 'Automatic generating of XML feeds',
      autoGenerateFeedsHelper: 'After activation',
      recountButton: 'Pricelist recalculation',
    },
    shoppingList: {
      rename: 'Rename',
      quantityAbbreviation: 'Pcs',
    },
    app: {
      products: {
        stockQuantityInfo: {
          onStock: 'in stock',
          notOnStock: 'not available',
          notOnStockKnownWhenExpected: 'expected date {timing}',
          pcs: '{qty, plural, one {{qtyF} pc in stock} few {{qtyF} pcs in stock} other {{qtyF} pcs in stock}}',
        },
      },
      pickDeliveryPlace: {
        label: 'Shipping address',
        helperText: 'Choose branch',
        requiredError: 'Please choose the branch',
      },
      orderSummary: {
        label: '',
      },
      shoppingCart: {
        emptyShoppingCart: 'Empty basket',
        orderNumber: 'Order number',
        noteForSupplier: 'Note',
        requiredDateOfDelivery: 'Requested delivery date',
        requiredDateOfDeliveryHelper: 'Please fill according to delivery terms',
        priceWithTax: 'Price including VAT',
        priceWithoutTax: 'Total excl. VAT',
        createOrder: 'Create order',
        resetCart: 'Basket reset',
        acceptReset: 'Confirm reset',
        orderButton: 'Order',
        notEnoughCZK: 'You order is lower than 3500 Kč. You may be charged by transport costs.',
        notEnoughEUR: 'You order is lower than 250 EUR. You may be charged by transport costs.',
        popup: {
          label: 'You have already items in basket',
          info: 'You have already items in basket',
          confirm: 'OK',
          emptyShoppingCart: 'Empty basket has been created',
          continueWithCart: 'Continue in open basket',
        },
      },
      continueShopping: {
        buttonLabel: 'Continue in purchase',
        thanks: 'Thank you for your order',
        infoBelow: 'Information about you order',
        orderNo: 'Order number',
      },
      specialMenuItems: {
        campaign: 'Campaigns',
        introduction: 'News',
        current: 'ZooPartner – current',
        new: 'Zoopartner - new',
        sale: 'Saleout',
        vetExclusive: 'Vet exclusive',
        zd_2023: 'Zákaznické dny',
        expiration: 'Short expiration',
        catalogue: 'All products',
        massInsert: 'Mass import',
        downloadableDocuments: 'Download documents',
        termsAndConditions: 'General Business Terms',
      },
      campaign: {
        palettes: 'Pallets',
        cardboard: 'Cartoon',
        display: 'Display',
        searchDepartment: 'Search in category',
        name: 'Name',
        code: 'Code',
        cheapest: 'Price-ascending',
        mostExpensive: 'Price-descending',
        changePage: 'Change page',
        goTo: 'Skip',
        searchCatalogue: 'Search in all categories',
        searchFoundNothing: 'Please choose the item.',
      },
      productInfo: {
        package: 'Packaging',
        withTax: 'incl. VAT',
        withoutTax: 'excl. VAT',
        priceWithTax: 'Price incl VAT',
        priceWithoutTax: 'Price excl VAT',
        basePriceWithTax: 'Base price incl VAT',
        basePriceWithoutTax: 'Base price excl VAT',
        priceQuantity: 'Price/pcs',
        expiration: 'Expiration',
        minimalOrder: 'MinOrdQty',
        buyButton: 'Buy',
        tax: 'VAT',
        quantity: 'Amount',
        suppliedQuantity: 'Delivered amount',
        dmicWithTax: 'RRIP incl VAT',
        dmocWithTax: 'RRP incl VAT',
        withTaxForPiece: 'incl VAT/pcs',
        withoutTaxForPiece: 'excl VAT/pcs',
        questionButton: 'I have a question',
        description: 'Details',
        parameters: 'Parameters',
        ean: 'EAN',
        hide: 'Hide',
        show: 'Display',
        change: {
          title: 'Replace product',
          text: 'pcs of original product will be replaced',
          pack: 'package',
          packs: 'packages',
          noOfPacks: 'psc in package',
          wishToChange: 'Would you like to replace product?',
        },
        cheaper: {
          title: 'cheaper product variants',
          text: 'You can buy next variants in product detail',
          one: 'You have 1 product in the basket which can be bought cheaper',
          moreThanFour1: 'You have in the basket',
          moreThanFour2: 'products which can be bought cheaper',
          notZero1: 'In the basket are',
          notZero2: 'products which can be bought cheaper',
          palett: 'Pallettes must be on separate order',
        },
      },
      vlp: {
        title: 'Sales restrictions',
        label: 'VLP',
      },
      massInsert: {
        textLines: 'Text rows',
        textLinesHelper:
          "Enter the values of 'SKU; QUANTITY' or 'SKU quantity' e.g. '453-1063;2'. For more than 1 product there must be another SKU on a new line. You can also use the import option from Excel by copying and then pasting columns.",
        checkFormat: 'Format check',
        afterCheckFormat: 'After format check',
        putInCart: 'To the basket',
        success: 'All products has been added to the basket',
        error: {
          noData: 'No data input',
          nothingToImport: 'Nothing to import',
          wrongFormat: 'Invalid format',
          wrongID: 'Invalid product ID',
          wrongQuantity: 'Invalid amount',
          notFindItem: 'Unknown product',
        },
      },
      backButton: {
        label: 'Back',
      },
      homeButton: {
        label: 'Home',
      },
    },
  },
};

export default messages;

const messages = {
  pl: {
    invoices: {
      actions: {
        addNewInvoice: 'Dodaj nową fakturę',
        all: 'Wszystkie faktury',
        searchInvoice: 'Wyszukaj produkty na fakturze ',
      },
      listPage: {
        title: 'Faktury',
        table: {
          filter: {
            number: 'Numer',
          },
          columns: {
            number: 'Numer',
            invoiceDate: 'Data wystawienia',
            dueDate: 'Data płatności ',
            total: 'Razem',
            totalWithTax: 'Razem z VAT',
            billingAddress: 'Adres dostawy ',
            orderNumber: 'Numer zamówienia ',
            unpaidAmount: 'Kwota do spłaty ',
            download: 'Pobierać',
          },
        },
      },
      invoice: {
        newEntity: 'Nowa faktura',
        number: {
          label: 'Numer',
          helperText: '',
          helperTextPreview: '',
        },
        invoiceDate: {
          label: 'Data wystawienia',
          helperText: '',
          helperTextPreview: '',
        },
        total: {
          label: 'Razem',
          helperText: '',
          helperTextPreview: '',
        },
        totalWithTax: {
          label: 'Razem z VAT',
          helperText: '',
          helperTextPreview: '',
        },
        billingAddress: {
          label: 'Adres dostawy ',
          helperText: '',
          helperTextPreview: '',
        },
        orderNumber: {
          label: 'Numer zamówienia ',
          helperText: '',
          helperTextPreview: '',
        },
        deliveryNoteNumber: {
          label: 'Numer listu przewozowego ',
          helperText: '',
          helperTextPreview: '',
        },
        paid: {
          label: 'Zapłacono',
          helperText: '',
          helperTextPreview: '',
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Linie faktury',
          columns: {
            priceUnit: 'Cena/szt ',
          },
        },
      },
    },
  },
};

export default messages;

const messages = {
  cs: {
    creditNotes: {
      actions: {
        addNewCreditNote: 'Přidat nový dobropis',
        all: 'Všechny dobropisy',
      },
      listPage: {
        title: 'Dobropisy',
        table: {
          filter: {
            number: 'Číslo',
          },
          columns: {
            number: 'Číslo',
            invoiceDate: 'Datum vystavení',
            total: 'Celkem',
            totalWithTax: 'Celkem s DPH',
            billingAddress: 'Fakturační adresa',
            orderNumber: 'Číslo objednávky',
          },
        },
      },
      creditNote: {
        newEntity: 'Nový dobropis',
        number: {
          label: 'Číslo',
          helperText: '',
          helperTextPreview: '',
        },
        invoiceDate: {
          label: 'Datum vystavení',
          helperText: '',
          helperTextPreview: '',
        },
        total: {
          label: 'Celkem',
          helperText: '',
          helperTextPreview: '',
        },
        totalWithTax: {
          label: 'Celkem s DPH',
          helperText: '',
          helperTextPreview: '',
        },
        billingAddress: {
          label: 'Fakturační adresa',
          helperText: '',
          helperTextPreview: '',
        },
        orderNumber: {
          label: 'Číslo objednávky',
          helperText: '',
          helperTextPreview: '',
        },
      },
      scoringBlog: {
        creditNoteRowsBlogPreview: {
          title: 'Řádky dobropisu',
          columns: {
            priceUnit: 'Cena/ks',
          },
        },
      },
    },
  },
};

export default messages;

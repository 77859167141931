const messages = {
  cs: {
    complaintClaims: {
      listPage: {
        title: 'Reklamace',
        table: {
          filter: {
            number: 'Číslo',
            invoiceNumber: 'Číslo faktury',
          },
          columns: {
            number: 'Číslo',
            invoiceNumber: 'Číslo faktury',
            state: 'Stav',
            creationDate: 'Datum vytvoření',
            lastChangeDate: 'Datum poslední změny',
          },
        },
      },
      states: {
        new: 'Vytvořeno',
        processing: 'Probíhá zpracování',
        accepted: 'Přijatá',
        rejected: 'Zamítnutá',
      },
      complaintClaim: {
        newEntity: 'Nová reklamace',
        invoiceNumber: {
          label: 'Číslo faktury',
          helperText: '',
          helperTextPreview: '',
        },
        lastChangeDate: {
          label: 'Datum poslední změny',
          helperText: '',
          helperTextPreview: '',
        },
        creationDate: {
          label: 'Datum vytvoření',
          helperText: '',
          helperTextPreview: '',
        },
        state: {
          label: 'Stav',
          helperText: '',
          helperTextPreview: '',
        },
        claimedQuantity: {
          label: 'Reklamované množství',
          helperText: '',
          helperTextPreview: '',
        },
        problem: {
          label: 'Problém',
          helperText: '',
          helperTextPreview: '',
        },
        reason: {
          label: 'Důvod',
          helperText: '',
          helperTextPreview: '',
        },
        subject: {
          text: {
            label: 'Reklamovaná položka',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            name: {
              label: 'Název produktu',
              helperText: '',
              helperTextPreview: '',
            },
            sku: {
              label: 'SKU',
              helperText: '',
              helperTextPreview: '',
            },
          },
        },
      },
    },
  },
};

export default messages;

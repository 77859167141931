import invariant from 'tiny-invariant';

function transformComplaintClaim<T extends { id: string }>(
  locale: string,
  complaintClaims: T[],
): (T & { href: string })[] {
  invariant(Array.isArray(complaintClaims), 'transformComplaintClaim requires complaintClaims to be defined');

  const buildHref = (complaintClaim: T): string => {
    return `/profil/reklamace/${complaintClaim.id}`;
  };

  return complaintClaims.map((complaintClaim: T) => ({
    ...complaintClaim,
    href: buildHref(complaintClaim),
  }));
}

export default transformComplaintClaim;

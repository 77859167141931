import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/ClearTwoTone';
import LockIcon from '@material-ui/icons/LockTwoTone';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/SearchTwoTone';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import DialogConfirm from '@sprinx/react-mui-components/DialogConfirm';
import TextField from '@sprinx/react-mui-fields/TextField';
import React, { useCallback, useState } from 'react';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { catalogueGlobalFilterState } from '../../../api/products/catalogue';
import ShoppingCartAppBarAction from '../../../components/ShoppingCartAppBarAction';
import LoginPageLogo from '../../../pages/LoginPage/LoginPageLogo';
import AppBarActionLocale from './AppBarActionLocale';
import MainNavigationContentItemProfile from './MainNavigationContentItemProfile';

export type AppHeaderClassKey = 'root' | 'menuButton' | 'toolbar' | 'title' | 'logo';

export interface AppHeaderProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.appBar.background,
      color: theme.palette.appBar.color.contrastText,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logo: {
      width: theme.spacing(12),
      display: 'flex',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 1),
    },
    title: {
      ...theme.typography.h5,
      color: theme.palette.appBar.color.logo,
      fontWeight: theme.typography.fontWeightBold,
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      textDecoration: 'none',
      maxWidth: '150px',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    startIcon: {
      position: 'absolute',
      left: 0,
      top: theme.spacing(3),
      backgroundColor: 'none',
      fill: 'white',
    },
    endIcon: {
      position: 'absolute',
      right: 0,
      top: theme.spacing(2),
      backgroundColor: 'inherit',
      '& svg': {
        fill: '#fafafa',
      },
    },
    search: {
      flexGrow: 5,
      position: 'relative',
      maxWidth: '500px',
      marginRight: 'auto',
    },
    searchMobie: {
      display: 'none',
      backgroundColor: 'inherit',
      border: 'none',
      cursor: 'pointer',
      marginLeft: 'auto',
      '& svg': {
        fill: '#fafafa',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    backButton: {
      backgroundColor: 'white',
      marginRight: 'auto',
      '&:hover': {
        backgroundColor: 'white',
      },
      '&:focus': {
        backgroundColor: 'white',
      },
    },
    textField: {
      '& label + .MuiInput-formControl': {
        paddingLeft: theme.spacing(6),
        color: 'white',
        '&:hover:before': {
          borderBottom: '1px solid white',
        },

        '&:before': {
          borderBottom: '1px solid white',
        },
      },
      '& label': {
        paddingLeft: theme.spacing(6),
        color: 'white',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
  }),
  { name: 'AppHeader' },
);

export default function AppHeader({ handleDrawerToggle }: AppHeaderProps): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const [logoutConfirm, setLogoutConfirm] = React.useState<boolean>(false);
  const autoSubmitRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  const [initialState, setFilter] = useRecoilState(catalogueGlobalFilterState);
  const match = useRouteMatch();
  const showFilter = match.url.startsWith('/catalogue') || match.url.startsWith('/special');
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const handleClick = React.useCallback(() => setMobileSearchOpen((v) => !v), []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const t = useTranslate();

  const filterOnChange = (value: any) => {
    if (value && match.url !== '/catalogue') {
      history.push('/catalogue');
    }

    if (autoSubmitRef.current) clearTimeout(autoSubmitRef.current);
    autoSubmitRef.current = setTimeout(() => {
      setFilter({ searchText: value });
    }, 10);
  };

  const handleResetSearchText = useCallback(() => {
    setFilter({ searchText: undefined });
  }, [setFilter]);

  return (
    <AppBar className={classes.root}>
      <div className={classes.toolbar}>
        <IconButton
          edge='start'
          className={classes.menuButton}
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        {!(isMobile && mobileSearchOpen) && (
          <Link
            component={RouterLink}
            to='/catalogue'
            color='inherit'
            variant='inherit'
            className={classes.title}
            noWrap
          >
            <LoginPageLogo className={classes.logo} />
          </Link>
        )}
        <Button
          className={classes.backButton}
          variant='outlined'
          size='small'
          color='default'
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
        >
          {t('app/backButton/label')}
        </Button>
        {(!isMobile || mobileSearchOpen) && showFilter && (
          <div className={classes.search}>
            <TextField
              margin='dense'
              fullWidth
              label={t('app/campaign/searchCatalogue')}
              value={initialState.searchText}
              className={classes.textField}
              onChange={filterOnChange}
            />
            <SearchIcon color='primary' fontSize='default' className={classes.startIcon} />

            {initialState.searchText && initialState.searchText.length > 0 && (
              <IconButton onClick={handleResetSearchText} className={classes.endIcon}>
                <ClearIcon fontSize='small' />
              </IconButton>
            )}
          </div>
        )}
        {isMobile && showFilter && (
          <SearchMobile mobileSearchOpen={mobileSearchOpen} onClick={handleClick} className={classes.searchMobie} />
        )}
        <AppBarActionLocale />
        <MainNavigationContentItemProfile />
        <ShoppingCartAppBarAction color='inherit' />
        <Tooltip title={t('homePage/signout/label')}>
          <IconButton
            edge='end'
            color='inherit'
            onClick={() => {
              setLogoutConfirm(true);
            }}
          >
            <LockIcon />
          </IconButton>
        </Tooltip>

        <DialogConfirm
          open={logoutConfirm}
          onClose={() => {
            setLogoutConfirm(false);
          }}
          onConfirm={() => {
            history.push('/odhlaseni');
          }}
          title={t('homePage/signout/popupLabel')}
        >
          <div>{t('homePage/signout/question')}</div>
        </DialogConfirm>
      </div>
    </AppBar>
  );
}
const SearchMobile: React.FC<{ className?: string; mobileSearchOpen: boolean; onClick: () => void }> = ({
  className,
  onClick,
  mobileSearchOpen,
}) => (
  <button className={className} onClick={onClick}>
    {!mobileSearchOpen ? <SearchIcon /> : <ClearIcon />}
  </button>
);

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import LanguageIcon from '@material-ui/icons/Language';
import useChangePreferredLocale from '@sprinx/react-globalize/useChangePreferredLocale';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { supportedLocalesState } from '../../../api/appState';
import { useSetCustomerLocale } from '../../../api/customer/customer';

export type MainNavigationContentItemProfileProps = StandardDivProps<MainNavigationContentItemProfileClassKey> & {
  classes?: Partial<Record<MainNavigationContentItemProfileClassKey, string>>;
  className?: string;
};

export type MainNavigationContentItemProfileClassKey = 'root';

const themeSettings = { name: 'MainNavigationContentItemProfile' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentItemProfileClassKey, {}>({
      root: { color: theme.palette.common.white },
    }),
  themeSettings,
);

const AppBarActionLocale: React.FC<MainNavigationContentItemProfileProps> = ({ className, classes: pClasses }) => {
  const classes = useStyles({ classes: pClasses });
  //const locale = useRecoilValue(localeState);
  const supportedLocales = useRecoilValue(supportedLocalesState);
  const changePreferredLocale = useChangePreferredLocale();
  const t = useTranslate();
  const setCustomerLocale = useSetCustomerLocale();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSetLocale = useCallback(
    (nextLocale: string) => async () => {
      if (changePreferredLocale) {
        await setCustomerLocale(nextLocale);
        changePreferredLocale(nextLocale);
      }
      setAnchorEl(null);
    },
    [changePreferredLocale, setCustomerLocale],
  );
  return (
    <>
      <Tooltip title={t('homePage/login/changeLocale')} className={clsx(classes.root, className)}>
        <IconButton onClick={handleMenu}>
          <LanguageIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {supportedLocales.map((value) => (
          <MenuItem key={value.locale} onClick={handleSetLocale(value.locale)}>
            {value.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

AppBarActionLocale.displayName = 'AppBarActionLocale';

export default withThemeProps(themeSettings)(AppBarActionLocale);

// import React from 'react';
// import PropTypes from 'prop-types';
import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMessages from '@sprinx/react-i18n/react-com-orders/cs';
import comMessages from '@sprinx/react-i18n/react-com/cs';
import formsMessages from '@sprinx/react-i18n/react-forms/cs';
import compMessages from '@sprinx/react-i18n/react-mui-components/cs';
import fieldMessages from '@sprinx/react-i18n/react-mui-fields/cs';
import pagesMessages from '@sprinx/react-i18n/react-mui-pages/cs';
import tablesMessages from '@sprinx/react-i18n/react-mui-tables/cs';
import shoppingCartMessages from '@sprinx/react-i18n/react-shopping-cart/cs';
import shoppingListMessages from '@sprinx/react-i18n/react-shopping-lists/cs';
import messages from './i18n/cs';
//
import complaintClaimsMessages from './i18n/react-complaint-claims-cs';
import creditNotesMessages from './i18n/react-credit-notes-cs';
import invoicesMessages from './i18n/react-invoices-cs';

function MessagesCs({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMessages,
    fieldMessages,
    tablesMessages,
    formsMessages,
    pagesMessages,
    comMessages,
    shoppingListMessages,
    shoppingCartMessages,
    comOrdersMessages,
    invoicesMessages,
    creditNotesMessages,
    complaintClaimsMessages,
    messages,
  ]);
}

// MessagesCs.propTypes = {
//   children: PropTypes.func.isRequired,
// };

export default MessagesCs;

import { ApplicationSettings } from './@sprinx/react-after-razzle';

const locale =
  (typeof window !== 'undefined' &&
    window.localStorage &&
    (window.localStorage.getItem('preferredLocale') as string | undefined | null)) ||
  process.env.RAZZLE_SX_LOCALE ||
  'cs';

const applicationSettings: ApplicationSettings = {
  currency: process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK',
  supportedCurrencies: ['CZK', 'EUR'],
  // locale: process.env.RAZZLE_SX_LOCALE || 'cs',
  locale,
  supportedLocales: [
    { locale: 'cs', label: 'Česky' },
    { locale: 'en', label: 'English' },
    // { locale: 'de', label: 'Deutsch' },
    { locale: 'pl', label: 'Polska' },
  ],
  pricesType: 'B2B',
  region: process.env.RAZZLE_SX_REGION || '_default',

  // TODO: add to AppProvider
  anonymouseUserId: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'anonymouse',
};

export default applicationSettings;

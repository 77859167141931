// import React from 'react';
// import PropTypes from 'prop-types';
import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMeplages from '@sprinx/react-i18n/react-com-orders/pl';
import comMeplages from '@sprinx/react-i18n/react-com/pl';
import formsMeplages from '@sprinx/react-i18n/react-forms/pl';
import compMeplages from '@sprinx/react-i18n/react-mui-components/pl';
import fieldMeplages from '@sprinx/react-i18n/react-mui-fields/pl';
import pagesMeplages from '@sprinx/react-i18n/react-mui-pages/pl';
import tablesMeplages from '@sprinx/react-i18n/react-mui-tables/pl';
import shoppingCartMeplages from '@sprinx/react-i18n/react-shopping-cart/pl';
import shoppingListMeplages from '@sprinx/react-i18n/react-shopping-lists/pl';
import meplages from './i18n/pl';
//
import complaintClaimsMeplages from './i18n/react-complaint-claims-pl';
import creditNotesMeplages from './i18n/react-credit-notes-pl';
import invoicesMeplages from './i18n/react-invoices-pl';

function MeplagesPl({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMeplages,
    fieldMeplages,
    tablesMeplages,
    formsMeplages,
    pagesMeplages,
    comMeplages,
    shoppingListMeplages,
    shoppingCartMeplages,
    comOrdersMeplages,
    invoicesMeplages,
    creditNotesMeplages,
    complaintClaimsMeplages,
    meplages,
  ]);
}

// MeplagesPl.propTypes = {
//   children: PropTypes.func.isRequired,
// };

export default MeplagesPl;

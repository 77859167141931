import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import React from 'react';
import { useRecoilState } from 'recoil';
import {
  ShoppingCartDecisionChoices,
  shoppingCartInitializationDecisionState,
  shoppingCartState,
  useShoppingCartProcessUserDecisionCall,
} from '../../api/shoppingCart';
import { useShowSnackbarMessage } from '../../api/snackbars';

export const DECISION_CHOICES: Record<'empty' | 'merge' | 'takeOver', ShoppingCartDecisionChoices> = {
  empty: 'empty',
  merge: 'merge',
  takeOver: 'take-over',
};

const ShoppingCartDecisionCartInOtherSession: React.FC = () => {
  // const t = useTranslate();
  const showSnackbarMessage = useShowSnackbarMessage();

  const [currenctCart, setCart] = useRecoilState(shoppingCartState);
  const [decision, setDecision] = useRecoilState(shoppingCartInitializationDecisionState);

  const processUserDecision = useShoppingCartProcessUserDecisionCall();

  /**
   * handleDecision
   */
  const handleDecision = React.useCallback(
    (decisionChoice) => () => {
      processUserDecision({
        decision: decisionChoice,
        previousCartId: decision?.cart?.id,
        mergeWith: [DECISION_CHOICES.merge].includes(decisionChoice) ? { id: currenctCart?.id } : undefined,
      })
        .then((res) => {
          setCart(res.cart || undefined);
          setDecision(undefined);
          showSnackbarMessage(
            res.status === 'created'
              ? t('app/shoppingCart/popup/emptyShoppingCart')
              : t('app/shoppingCart/popup/continueWithCart'),
            {
              variant: 'info',
              preventDuplicate: true,
            },
          );
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('AppShoppingProvider process user decision error...', err);
          setDecision(undefined);
        });
    },
    [currenctCart?.id, decision?.cart?.id, processUserDecision, setCart, setDecision, showSnackbarMessage],
  );
  const t = useTranslate();

  return decision && decision.status === 'cart-in-other-session' ? (
    <Dialog open onClose={handleDecision(DECISION_CHOICES.takeOver)}>
      <DialogTitle>{t('app/shoppingCart/popup/label')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('app/shoppingCart/popup/info')}</DialogContentText>
        <DialogActions>
          <Button color='secondary' variant='outlined' onClick={handleDecision(DECISION_CHOICES.takeOver)}>
            {t('app/shoppingCart/popup/confirm')}
          </Button>
        </DialogActions>
      </DialogContent>
      {/* <DialogTitle>Máte již rozpracovaný košík</DialogTitle>
      <DialogContent>
        <DialogContentText component='div'>
          <div style={{ paddingBottom: 16 }}>Chcete pokračovat v již rozpracovaném nákupu?</div>
          {decision.cart?.cartContent?.subjects && decision.cart?.cartContent?.subjects.length > 0 && (
            <>
              <div style={{ fontWeight: 'bolder' }}>Obsah košíku</div>
              <List dense>
                {decision.cart?.cartContent?.subjects?.map((s, i) => (
                  <ListItem key={i.toString()}>
                    <div style={{ paddingRight: 16 }}>
                      <ProductImage size='mini' src={(s.product as any).image} />
                    </div>
                    <ListItemText
                      primary={t(typeof s.product === 'string' ? s.product : s.product.name, { acceptString: true })}
                      secondary={`${s.quantity} ks`}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {!decision.cart?.cartContent?.subjects ||
            (decision.cart?.cartContent?.subjects.length <= 0 && (
              <div style={{ color: '#aaa' }}>Košík neobsahuje žádné položky.</div>
            ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  color='primary' onClick={handleDecision(DECISION_CHOICES.empty)}>Nový košík</Button>
        {currenctCart && currenctCart.cartContent.subjects.length > 0 ? (
          <>
            <Button color='primary' onClick={handleDecision(DECISION_CHOICES.merge)}>Sloučit s rozpracovaným</Button>
            <Button color='primary' onClick={handleDecision(DECISION_CHOICES.takeOver)}>Použít pouze rozpracovaný</Button>
          </>
        ) : (
          <Button color='primary' onClick={handleDecision(DECISION_CHOICES.takeOver)}>Použít rozpracovaný</Button>
        )}
      </DialogActions> */}
    </Dialog>
  ) : null;
};

ShoppingCartDecisionCartInOtherSession.displayName = 'ShoppingCartDecisionCartInOtherSession';

export default ShoppingCartDecisionCartInOtherSession;

const messages = {
  pl: {
    creditNotes: {
      actions: {
        addNewCreditNote: 'Nowa nota kredytowa',
        all: 'Wszystkie noty kredytowe',
      },
      listPage: {
        title: 'Noty kredytowe',
        table: {
          filter: {
            number: 'Numer',
          },
          columns: {
            number: 'Numer',
            invoiceDate: 'Data wystawienia',
            total: 'Razem',
            totalWithTax: 'Razem z VAT',
            billingAddress: 'Adres do faktury',
            orderNumber: 'Numer zamówienia ',
          },
        },
      },
      creditNote: {
        newEntity: 'Nowa nota kredytowa',
        number: {
          label: 'Numer',
          helperText: '',
          helperTextPreview: '',
        },
        invoiceDate: {
          label: 'Data wystawienia',
          helperText: '',
          helperTextPreview: '',
        },
        total: {
          label: 'Razem',
          helperText: '',
          helperTextPreview: '',
        },
        totalWithTax: {
          label: 'Razem z VAT',
          helperText: '',
          helperTextPreview: '',
        },
        billingAddress: {
          label: 'Adres do faktury',
          helperText: '',
          helperTextPreview: '',
        },
        orderNumber: {
          label: 'Numer zamówienia ',
          helperText: '',
          helperTextPreview: '',
        },
      },
      scoringBlog: {
        creditNoteRowsBlogPreview: {
          title: 'Linie noty kredytowej',
          columns: {
            priceUnit: 'Cena/szt',
          },
        },
      },
    },
  },
};

export default messages;
